import Cookies from 'cookies-js';

const BannerPromotion = {

  el: '#banner-promotion',

  ui: {
    'promoButton': '[data-show-promo]'
  },

  events: {
    'click {promoButton}': 'triggerModal'
  },

  initialize: function () {
    this.boundOnEmailSignup = this.onEmailSignup.bind(this);
    document.addEventListener('emailSignup', this.boundOnEmailSignup, false);

    if (this.hasSubscribed()) {
      this.onEmailSignup()
    }
  },

  hasSubscribed() {
    // See `./email-signup.js`
    return Cookies.get('emailSignup') === '1';
  },

  onEmailSignup: function (event) {
    let className = '-email-submitted';
    this.el.classList.add(className);
    document.removeEventListener('emailSignup', this.boundOnEmailSignup);
  },

  // Trigger the modal by dispatching a custom event. This is received
  // by `./banner-promotion-modal.js`
  triggerModal: function () {
    var event = document.createEvent('Event');
    event.initEvent('forceShowBannerPromotionModal', true, true);
    var modal = document.getElementById('banner-promotion-modal');
    modal && modal.dispatchEvent(event);
  }
}

export default BannerPromotion;
