/**
 * hasLocalStorage
 * A boolean value signifying if localStorage exists
 */

var hasLocalStorage = false;

try {
  localStorage.setItem('test', 1);
  localStorage.removeItem('test');

  hasLocalStorage = true;
} catch (e) {
  console && console.warn('No localStorage detected.');
}

export default hasLocalStorage;
