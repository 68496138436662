let AmbientVideo = {
  el: '[data-component="ambient-video"]',

  ui: {
    video: '[data-ambient-video="video"]',
    toggle: '[data-ambient-video="toggle"]',
    togglePlayIcon: '[data-ambient-video="toggle-play"]',
    togglePauseIcon: '[data-ambient-video="toggle-pause"]'
  },

  events: {
    'click {toggle}': 'videoToggle'
  },

  initialize: function () {
    this.motionMQL = window.matchMedia('(prefers-reduced-motion: reduce)');
    this.motionMQL.addEventListener('change', this.onMotionChange.bind(this))
    this.mobileMQL = window.matchMedia('(max-width: 768px)')

    // temporarily removed as this was not starting video reliably - WCS-6035
    // this.autoPlayCheck();
  },

  autoPlayCheck: function () {
    if (this.motionMQL.matches || this.mobileMQL.matches) {
      this.videoPause()
    } else {
      // load video, then when able to, play it
      this.ui.video[0].load()
      this.ui.video[0].addEventListener('canplaythrough', () => {
        this.videoPlay()
      }, { once: true })
    }
  },

  videoToggle: function () {
    if (this.ui.video[0].paused) {
      this.videoPlay()
    } else {
      this.videoPause()
    }
  },

  videoPlay: function () {
    this.ui.toggle[0].ariaPressed = true
    this.ui.togglePlayIcon[0].classList.add("no-display")
    this.ui.togglePauseIcon[0].classList.remove("no-display")
    this.ui.video[0].play()
  },

  videoPause: function () {
    this.ui.toggle[0].ariaPressed = false
    this.ui.togglePlayIcon[0].classList.remove("no-display")
    this.ui.togglePauseIcon[0].classList.add("no-display")
    this.ui.video[0].pause()
  },

  onMotionChange: function () {
    this.autoPlayCheck();
  },
}

export default AmbientVideo
