// This replaces the legacy inline javacript loader
import injectAll from "./loader/loader"
import docReady from "./utils/docready"

// make sure we inject after DOMContentLoaded
docReady(injectAll())

//  polyfill classList, but especially classlist.add and classList.remove for IE10 and IE11
//= require classlist

// polyfill position:sticky. use with .position-sticky class
import Stickyfill from 'stickyfilljs'
var stickyEls = document.querySelectorAll('.position-sticky');
Stickyfill.add(stickyEls);

import 'console-polyfill';

// polyfill smoothscroll
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// Permanent Site Components
import Washi from 'washi';
import GALinkDecorator from './components/ga-link-decorator';
import Menu from './components/menu';
import Togglable from './components/togglable';
import SkipLink from './components/skip-link';
import EmailSignup from './components/email-signup';
import PhotoGallery from './components/photo-gallery';
import VimeoVideo from './components/vimeo-video';
import YoutubeVideo from './components/youtube-video';
import Contribute from './components/contribute';
import Popup from './components/popup';
import InteractionModal from './components/interaction-modal';
import BannerPromotionModal from './components/banner-promotion-modal';
import BannerPromotion from './components/banner-promotion';
import EventTicketList from './ecomm/views/event-ticket-list';
import BasicTabs from './components/basic-tabs';
import Binder from './components/binder';
import NumberStepper from './components/number-stepper';
import TicketNumberStepper from './components/ticket-number-stepper';
import HideShow from './components/hide-show';
import AmbientVideo from './components/ambient-video';

Washi(GALinkDecorator);
Washi(Menu);
Washi(Togglable);
SkipLink();
Washi(EmailSignup);
Washi(PhotoGallery);
Washi(VimeoVideo);
Washi(YoutubeVideo);
Washi(Contribute);
Washi(Popup);
Washi(InteractionModal);
Washi(BannerPromotionModal);
Washi(BannerPromotion);
Washi(EventTicketList);
Washi(BasicTabs);
Washi(Binder);
Washi(NumberStepper);
Washi(TicketNumberStepper);
Washi(HideShow);
Washi(AmbientVideo);

import 'what-input';
import './sentry';


/**
 * New Homepage Blocks
 *
 */
import Carousel from './components/carousel';
import TVBlock from './components/tv-block';
import StickyNav from './components/sticky-nav';

Washi(Carousel);
Washi(TVBlock);
Washi(StickyNav);


/**
 * In-Park App Promo
 */
// import InParkAppPromo from './components/in-park-app-promo-modal';
// Washi(InParkAppPromo);

/**
 * Donation Modal Campaign
 * aka Patrons Lightbox
 *
 * This script has an accompanying view partial that must be un/commented out
 * in views/layouts/application.html.erb:
 *
 * render 'shared/donation_modal'
 */
// import DonationModal from './components/donation-modal';
// Washi(DonationModal);

/**
 * Advocacy Modal Campaign
 *
 * This script has an accompanying view partial that should be
 * added to views/layouts/application.html.erb:
 * render 'shared/advocacy_modal'
 */
// import AdvocacyModal from './components/advocacy-modal';
// Washi(AdvocacyModal);
