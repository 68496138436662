var Binder = {

  el: '[data-component="binder"]',

  ui: {
    home: '[data-binder="home"]',
    tabs: '[role="tab"]',
    tabpanels: '[role="tabpanel"]',
    toHome: '[data-binder="to-home"]',
  },

  events: {
    'click {tabs}': 'handleTabClick',
    'click {toHome}': 'handleToHomeClick',
  },

  initialize: function () {
    for (let i = 0; i < this.ui.tabpanels.length; i++) {
      this.hide(this.ui.tabpanels[i])
    }
  },

  handleTabClick: function (e) {
    var tabpanel = this.el.querySelector(`#${e.target.getAttribute('aria-controls')}`)
    this.hide(this.ui.home[0])
    this.show(tabpanel)
  },

  handleToHomeClick: function (e) {
    this.ui.tabpanels.forEach(tabpanel => this.hide(tabpanel))
    this.show(this.ui.home[0])
  },

  show: function (el) {
    el.removeAttribute('hidden')
  },

  hide: function (el) {
    el.setAttribute('hidden', true)
  },
}

export default Binder
