import Cookies from 'cookies-js';

var Contribute = {

  el: '.contribute__signup',

  ui: {
    close: '.contribute__signup__close'
  },

  events: {
    'click {close}': 'remove'
  },

  /**
   * See #1492 for more implementation details
   */
  remove: function () {
    this.el.parentNode.removeChild(this.el)
    Cookies.set('fixedEmailSignupClosed', '1');
  }

};

export default Contribute;
