const isIE = /MSIE|Trident/.test(window.navigator.userAgent)
const isIOSlt11 = /iP(hone|od|ad)/.test(window.navigator.platform) && /OS [1-10]/.test(window.navigator.userAgent)
const TAB_ID_SUFFIX_REGEX = new RegExp('-tab\$')
const TAB_TITLE_PREFIX_ATTR = 'data-basic-tabs-title-prefix'
const TAB_LINK_ATTR = 'data-basic-tabs-link'
const TAB_ANCHOR_ATTR = 'data-basic-tabs-anchor'
const KEYS = {
  end: 35,
  home: 36,
  left: 37,
  right: 39,
}

let state = {
  currentIndex: null,
  currentTab: null,
}

var BasicTabs = {

  el: '[data-component="basic-tabs"]',

  ui: {
    tabs: '[role="tab"]',
    tabPanelsContainer: '[data-basic-tabs="tabpanel-container"]',
    tabPanels: '[role="tabpanel"]',
    links: `[${TAB_LINK_ATTR}]`,
  },

  events: {
    'click {tabs}': 'handleTabClick',
    'keyup {tabs}': 'handleTabKeyup',
    'click {links}': 'handleLinkClick',
  },

  initialHash: window.location.hash,

  initialize: function () {
    const initialIndex = this.getInitialIndex()

    for (let i = 0; i < this.ui.tabs.length; i++) {
      if (i != initialIndex) {
        this.unsetPosition(i)
      }
    }

    this.setPosition(initialIndex)

    if (this.initialHash) {
      this.updateTitle(initialIndex)
    }
  },

  getInitialIndex() {
    let index = 0

    if (this.initialHash) {
      const tabIds = this.ui.tabs.map(tab => tab.id)
      const idFromHash = window.location.hash.replace(/^#/, '')
      const preselectedTabIndex = tabIds.indexOf(idFromHash)
      if (preselectedTabIndex > -1) {
        index = preselectedTabIndex
      }
    }

    return index
  },

  setPosition: function (index) {
    this.ui.tabs[index].setAttribute('aria-selected', true)
    this.ui.tabs[index].removeAttribute('tabindex')
    this.ui.tabPanels[index].removeAttribute('hidden')
  },

  unsetPosition: function (index) {
    this.ui.tabs[index].setAttribute('aria-selected', false)
    this.ui.tabs[index].setAttribute('tabindex', -1)
    this.ui.tabPanels[index].setAttribute('hidden', true)
  },

  updateUrl: function (tab) {
    const hash = '#' + tab.id.replace(TAB_ID_SUFFIX_REGEX, '')

    history.pushState(null, '', hash)
  },

  updateTitle: function (incomingIndex, outgoingTab) {
    const incomingTitlePrefix = this.ui.tabs[incomingIndex].getAttribute(TAB_TITLE_PREFIX_ATTR)
    let outgoingTitlePrefix = ''

    if (outgoingTab) {
      outgoingTitlePrefix = outgoingTab.getAttribute(TAB_TITLE_PREFIX_ATTR)
    }

    const outgoingTitlePrefixRegex = new RegExp(`\^${outgoingTitlePrefix}`)
    document.title = incomingTitlePrefix + document.title.replace(outgoingTitlePrefixRegex, '')
  },

  getCurrentTab: function () {
    // for IE-friendliness, must use `filter(x)[0]` not `find(x)`
    const currentTab = this.ui.tabs.filter(tab => tab.getAttribute('aria-selected') === 'true')[0]

    const currentIndex = this.ui.tabs.indexOf(currentTab)

    return { currentTab, currentIndex }
  },

  handleTabClick: function (e) {
    const incomingTab = e.target

    if (incomingTab.getAttribute('aria-selected') === 'true') {
      return
    }

    const incomingIndex = this.ui.tabs.indexOf(incomingTab)
    const { currentTab, currentIndex } = this.getCurrentTab()

    this.unsetPosition(currentIndex)
    this.setPosition(incomingIndex)
    this.updateUrl(incomingTab)
    this.updateTitle(incomingIndex, currentTab)
  },

  handleTabKeyup: function (e) {
    // key accessibility UX pattern follows https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-1/tabs.html

    const key = e.keyCode

    // oldschool `if (Object.values(KEYS).indexOf(key) < 0) return`
    const keys = Object.keys(KEYS).map(function (key) {
      return KEYS[key]
    })
    if (keys.indexOf(key) < 0) {
      return
    }

    e.preventDefault()

    let keyTab
    let { currentIndex } = this.getCurrentTab()

    switch (key) {
      case KEYS.left:
        if (currentIndex > 0) {
          keyTab = this.ui.tabs[currentIndex - 1]
        } else {
          keyTab = this.ui.tabs[this.ui.tabs.length - 1]
        }

        keyTab.focus()
        keyTab.click()
        break

      case KEYS.right:
        if (currentIndex < this.ui.tabs.length - 1) {
          keyTab = this.ui.tabs[currentIndex + 1]
        } else {
          keyTab = this.ui.tabs[0]
        }

        keyTab.focus()
        keyTab.click()
        break

      case KEYS.home:
        keyTab = this.ui.tabs[0]

        keyTab.focus()
        keyTab.click()
        break

      case KEYS.end:
        keyTab = this.ui.tabs[this.ui.tabs.length - 1]

        keyTab.focus()
        keyTab.click()
        break
    }
  },

  handleLinkClick: function (e) {
    const linkFor = e.target.getAttribute(TAB_LINK_ATTR)
    const { currentIndex } = this.getCurrentTab()
    const currentTab = this.ui.tabPanels[currentIndex]
    const anchor = currentTab.querySelector(`[${TAB_ANCHOR_ATTR}="${linkFor}"]`)

    if (!isIOSlt11) {
      anchor.focus({ preventScroll: true })
    }

    if (isIE) {
      window.scrollTo(0, anchor.getBoundingClientRect().top)
    } else {
      window.scrollTo({
        behavior: 'smooth',
        top: anchor.getBoundingClientRect().top,
      })
    }
  }
}

export default BasicTabs
