export default function () {
  /* via http://www.nczonline.net/blog/2013/01/15/fixing-skip-to-content-links/ */
  /* Fix Chrome IE9 hash change to has focus */
  window.addEventListener("hashchange", function (event) {
    var element = document.getElementById(location.hash.substring(1));

    if (element) {
      if (!/^(?:a|select|input|button|textarea)$/i.test(element.tagName)) {
        element.tabIndex = -1;
      }
      element.focus();
    }
  }, false);
}
