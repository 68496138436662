import Cookies from 'cookies-js';

var Popup = {

  el: '.popup',

  ui: {
    close: '.popup__close'
  },

  events: {
    'click {close}': 'remove'
  },

  remove: function () {
    this.el.parentNode.removeChild(this.el);

    var content = document.getElementById('#content');
    content && content.focus();

    Cookies.set('popupClosed', '1', {
      expires: 86400 // 1 day
    });
  }

};

export default Popup;
