import superagent from 'superagent';
import Cookies from 'cookies-js';

var EmailSignup = {

  el: '.email-signup',

  ui: {
    errorContainer: '[data-email-signup-error]',
    errorMessage: '[data-email-signup-error-message]'
  },

  events: {
    'submit': 'submit'
  },

  initialize() {
    if (this.isPersistent() && this.hasSubmitted()) {
      this.showSuccess();
    }
  },

  hasSubmitted() {
    return Cookies.get('emailSignup') === '1';
  },

  isPersistent() {
    return this.el.getAttribute('data-js-persistent') === 'true';
  },

  fireSignupEvent() {
    if (this.el.classList.contains('-banner-promotion-modal')) {
      var event = document.createEvent('Event');
      event.initEvent('emailSignup', true, true);
      document.dispatchEvent(event);
    }
  },

  showSuccess() {
    this.ui.errorContainer[0].classList.add("hide");
    this.el.classList.add('submitted');

    setTimeout(function () {
      this.el.classList.add('-out');
    }.bind(this), 500);
  },

  submit(e) {
    e.preventDefault();
    var action = this.el.getAttribute('data-js-action');
    var input = this.el.querySelector('input[name="mail"]');

    superagent
      .post(action)
      .send(new FormData(this.el))
      .end((err, res) => {
        if (res.body.status === "error") {
          this.handleError(res.body.message);
        } else {
          this.handleSuccess(input.value)
        }
      });
  },

  handleError(message) {
    this.ui.errorMessage[0].innerText = message;
    this.ui.errorContainer[0].classList.remove("hide");
  },

  handleSuccess(inputValue) {
    this.showSuccess();
    this.fireSignupEvent();
    this.trackSubmit(inputValue);
  },

  trackSubmit(email) {
    // GTM
    if (typeof dataLayer !== 'undefined') {
      var signupLocation = this.el.getAttribute('data-js-location');

      dataLayer.push({
        'event': 'emailSubmission',
        'emailSignupElement': signupLocation || 'Undefined',
        'submittedEmail': email || 'Undefined'
      });
    }

    Cookies.set('emailSignup', '1');
  }


};

export default EmailSignup;
